import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiurl } from "./api/config";
import { FaSpinner, FaExclamationTriangle } from "react-icons/fa";
import { Helmet, HelmetProvider } from "react-helmet-async";

const OriginDataPage: React.FC = () => {
  const [originHoData, setOriginHoData] = useState<[]>([]);
  const [originJbkData, setOriginJbkData] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(5);
  const [startDate, setStartDate] = useState<string>(""); // Tanggal mulai
  const [endDate, setEndDate] = useState<string>(""); // Tanggal sampai

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [hoResponse, jbkResponse] = await Promise.all([
          axios.get(`${apiurl}/originho`),
          axios.get(`${apiurl}/originjbk`),
        ]);

        setOriginHoData(hoResponse.data);
        setOriginJbkData(jbkResponse.data);
        setLoading(false);
      } catch (err) {
        setError("Gagal memuat data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFilter = (start: string, end: string) => {
    setStartDate(start);
    setEndDate(end);
    setCurrentPage(1); // Reset pagination to the first page when filtering
  };

  const filterByDateRange = (data: any[]) => {
    if (startDate && endDate) {
      return data.filter((item: any) => {
        const itemDate = new Date(item.tanggal);
        return (
          itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
        );
      });
    }
    return data;
  };

  const filteredHoData = filterByDateRange(originHoData);
  const filteredJbkData = filterByDateRange(originJbkData);

  const paginatedHoData = filteredHoData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const paginatedJbkData = filteredJbkData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Calculate Totals for Jbk
  const totalJbkCustomer = filteredJbkData.reduce(
    (acc: number, item: any) => acc + item.ordercustomer,
    0
  );

  const totalJbkCNKWIAndTPK = filteredJbkData.reduce(
    (acc: number, item: any) => acc + item.ordercnkwi + item.ordertpk,
    0
  );

  // Calculate Totals for Ho
  const totalHoCustomer = filteredHoData.reduce(
    (acc: number, item: any) => acc + item.ordercustomer,
    0
  );

  const totalHoCNKWIAndTPK = filteredHoData.reduce(
    (acc: number, item: any) => acc + item.ordercnkwi + item.ordertpk,
    0
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <FaSpinner className="animate-spin text-blue-500 text-4xl" />
        <p className="ml-4 text-blue-500 text-lg">Memuat data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <FaExclamationTriangle className="text-red-500 text-4xl" />
        <p className="ml-4 text-red-500 text-lg">{error}</p>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <div className="min-h-screen bg-gray-100 p-4">
        <Helmet>
          <title>Origin Order</title>
          <meta
            name="description"
            content="Halaman untuk menampilkan data OriginJbk dan OriginHo."
          />
        </Helmet>
  
        <div className="max-w-7xl mx-auto">
          {/* Title Section */}
          <h1 className="text-3xl font-bold text-center text-gray-800 mb-6 bg-slate-600">
            Data OriginJbk & OriginHo
          </h1>
  
          {/* Filter Date Section */}
          <div className="flex flex-col items-center justify-center mb-6 space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4">
            <label className="text-gray-600">Filter Tanggal:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => handleFilter(e.target.value, endDate)}
              className="px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => handleFilter(startDate, e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={() => handleFilter("", "")}
              className="px-4 py-2 bg-gray-300 rounded-lg shadow-md hover:bg-gray-400"
            >
              Reset
            </button>
          </div>
  
          <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
            Delivery Order Customer & Store
          </h1>
  
          {/* OriginJbk Data Section */}
          <h2 className="text-xl font-semibold text-gray-700 mt-10">
            Origin: DC KLS Jababeka
          </h2>
          <p className="text-right">
            Total Customer: <strong>{totalHoCustomer + totalJbkCustomer}</strong>
          </p>
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  <th className="py-2 px-4 bg-green-500 text-white text-center">
                    Tanggal
                  </th>
                  <th className="py-2 px-4 bg-green-500 text-white text-center">
                    Customer
                  </th>
                  <th className="py-2 px-4 bg-green-500 text-white text-center">
                    CN KWI
                  </th>
                  <th className="py-2 px-4 bg-green-500 text-white text-center">
                    TPK DK
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedJbkData.map((item: any, index: number) => (
                  <tr key={index} className="border-b">
                    <td className="py-2 px-4 text-gray-800 text-center">
                      {item.tanggal}
                    </td>
                    <td className="py-2 px-4 text-gray-800 text-center">
                      {item.ordercustomer}
                    </td>
                    <td className="py-2 px-4 text-gray-800 text-center">
                      {item.ordercnkwi}
                    </td>
                    <td className="py-2 px-4 text-gray-800 text-center">
                      {item.ordertpk}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="px-4 py-2 text-gray-700">
              <p className="text-right">
                Total Customer: <strong>{totalJbkCustomer}</strong>
              </p>
              <p className="text-right">
                Total CN KWI + TPK DK:{" "}
                <strong>{totalJbkCNKWIAndTPK}</strong>
              </p>
            </div>
          </div>
  
          {/* OriginHo Data Section */}
          <h2 className="text-xl font-semibold text-gray-700 mt-10 mb-6">
            Origin: HUB HO
          </h2>
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  <th className="py-2 px-4 bg-blue-500 text-white text-center">
                    Tanggal
                  </th>
                  <th className="py-2 px-4 bg-blue-500 text-white text-center">
                    Customer
                  </th>
                  <th className="py-2 px-4 bg-blue-500 text-white text-center">
                    CN KWI
                  </th>
                  <th className="py-2 px-4 bg-blue-500 text-white text-center">
                    TPK DK
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedHoData.map((item: any, index: number) => (
                  <tr key={index} className="border-b">
                    <td className="py-2 px-4 text-gray-800 text-center">
                      {item.tanggal}
                    </td>
                    <td className="py-2 px-4 text-gray-800 text-center">
                      {item.ordercustomer}
                    </td>
                    <td className="py-2 px-4 text-gray-800 text-center">
                      {item.ordercnkwi}
                    </td>
                    <td className="py-2 px-4 text-gray-800 text-center">
                      {item.ordertpk}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="px-4 py-2 text-gray-700">
              <p className="text-right">
                Total Customer: <strong>{totalHoCustomer}</strong>
              </p>
              <p className="text-right">
                Total CN KWI + TPK DK:{" "}
                <strong>{totalHoCNKWIAndTPK}</strong>
              </p>
            </div>
          </div>
  
          {/* Pagination Section */}
          <div className="flex items-center justify-center mt-6">
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
              className="px-4 py-2 bg-gray-300 rounded-l-lg hover:bg-gray-400"
            >
              Previous
            </button>
            <span className="px-4 py-2 bg-white border-t border-b border-gray-300">
              {currentPage}
            </span>
            <button
              disabled={
                currentPage * itemsPerPage >= filteredHoData.length &&
                currentPage * itemsPerPage >= filteredJbkData.length
              }
              onClick={() => setCurrentPage(currentPage + 1)}
              className="px-4 py-2 bg-gray-300 rounded-r-lg hover:bg-gray-400"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}  

export default OriginDataPage;
