import React, { useState } from "react";
import axios from "axios";
import { apiurl } from "./api/config"; // Import apiurl dari config.js

interface ArmadaInputPageProps {
  token: string; // Token untuk otorisasi
  onCancel: () => void; // Fungsi untuk menangani tombol Cancel
}

const ArmadaInputPage: React.FC<ArmadaInputPageProps> = ({
  token,
  onCancel,
}) => {
  const [formData, setFormData] = useState({
    inputid: "KLS001", // Default Input ID
    sameday: 0,
    lcl: 0,
    depoteknik: 0,
    reguler: 0,
    satelite: 0,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fungsi untuk menangani perubahan input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Fungsi untuk mengirim data ke API
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Menggunakan PUT untuk memperbarui data berdasarkan ID
      const response = await axios.put(
        `${apiurl}/inputarmada/${formData.inputid}`, // Endpoint API dengan ID
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Header otorisasi
          },
        }
      );

      alert("Data updated successfully!");
      console.log("Response:", response.data);

      // Reset form jika diperlukan (opsional)
      setFormData({
        inputid: "KLS001",
        sameday: 0,
        lcl: 0,
        depoteknik: 0,
        reguler: 0,
        satelite: 0,
      });
    } catch (error) {
      console.error("Error updating data:", error);

      // Tampilkan pesan error jika ada
      if (axios.isAxiosError(error) && error.response) {
        alert(`Error: ${error.response.data.message || "Update failed"}`);
      } else {
        alert("Failed to update data. Please try again.");
      }
    } finally {
      setIsSubmitting(false); // Set kembali tombol Submit
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h3 className="text-lg font-bold mb-4">Update Armada Data</h3>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded p-6">
        <div className="mb-3">
          <label htmlFor="inputid" className="block font-medium">
          </label>
          <input
            type="text"
            id="inputid"
            name="inputid"
            value={formData.inputid}
            className="w-full border border-gray-300 rounded px-3 py-2 bg-gray-100 cursor-not-allowed"
            readOnly
            hidden
          />
        </div>
        <div className="mb-3">
          <label htmlFor="sameday" className="block font-medium">
            Sameday
          </label>
          <input
            type="number"
            id="sameday"
            name="sameday"
            value={formData.sameday}
            onChange={handleInputChange}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="lcl" className="block font-medium">
            LCL
          </label>
          <input
            type="number"
            id="lcl"
            name="lcl"
            value={formData.lcl}
            onChange={handleInputChange}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="depoteknik" className="block font-medium">
            Depoteknik
          </label>
          <input
            type="number"
            id="depoteknik"
            name="depoteknik"
            value={formData.depoteknik}
            onChange={handleInputChange}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="reguler" className="block font-medium">
            Reguler
          </label>
          <input
            type="number"
            id="reguler"
            name="reguler"
            value={formData.reguler}
            onChange={handleInputChange}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="satelite" className="block font-medium">
            Satelite
          </label>
          <input
            type="number"
            id="satelite"
            name="satelite"
            value={formData.satelite}
            onChange={handleInputChange}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onCancel}
            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`${
              isSubmitting ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
            } text-white py-2 px-4 rounded`}
          >
            {isSubmitting ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ArmadaInputPage;
